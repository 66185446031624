import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cva } from "class-variance-authority";

import { OptionalObject } from "@ag/utils/types";

import { Icon } from "~assets";
import { Chip, ChipVariant } from "~atoms";
import { cn } from "~utils";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type AccordionMultiProps = {
  defaultValue?: string[];
  isMultiple: true;
} & OptionalObject<{
  value: string[];
  onValueChange: (values: string[]) => void;
}>;

type AccordionSingleProps = {
  defaultValue?: string;
  isMultiple?: false;
} & OptionalObject<{
  value: string;
  onValueChange: (values: string) => void;
}>;

type RootProps = React.PropsWithChildrenRequired<
  AccordionMultiProps | AccordionSingleProps
>;

export function Root({
  children,
  defaultValue,
  value,
  isMultiple,
  onValueChange,
}: RootProps) {
  // Need this conditional templating bc. the types are using unions based on "multiple".
  if (isMultiple) {
    return (
      <AccordionPrimitive.Accordion
        className="grid gap-3"
        defaultValue={defaultValue}
        type="multiple"
        value={value as string[]}
        onValueChange={onValueChange}
      >
        {children}
      </AccordionPrimitive.Accordion>
    );
  }

  return (
    <AccordionPrimitive.Accordion
      className="grid gap-3"
      collapsible
      defaultValue={defaultValue}
      type="single"
      value={value as string}
      onValueChange={onValueChange}
    >
      {children}
    </AccordionPrimitive.Accordion>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/
type ItemProps = React.PropsWithChildrenRequired<{
  value: string;
  isDisabled?: boolean;
}>;

const item = cva(
  [
    "grid",
    "gap-0",
    "shadow-100",
    "overflow-hidden",
    "rounded",
    "border",
    "border-sky-200",
  ],
  {
    variants: {
      isDisabled: {
        true: "border-none bg-grey-200",
        false: "bg-grey-white",
      },
    },
  },
);

export function Item({ children, value, isDisabled }: ItemProps) {
  return (
    <AccordionPrimitive.Item
      className={item({ isDisabled })}
      value={value}
      disabled={isDisabled}
    >
      {children}
    </AccordionPrimitive.Item>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/
type HeaderProps = React.PropsWithChildrenRequired<{
  status?: {
    label: string;
    variant?: ChipVariant;
  };
}>;

export function Header({ children, status }: HeaderProps) {
  return (
    <AccordionPrimitive.Header
      className={cn(
        "grid grid-cols-[1fr,auto] items-center justify-start gap-4",
        "group border-none bg-transparent px-4 py-3.5 text-p2 font-medium outline-none",
        "hover:cursor-pointer focus:outline-none disabled:cursor-not-allowed ",
      )}
      asChild
      onClick={event => event.stopPropagation()}
    >
      <AccordionPrimitive.Trigger>
        <div className="flex flex-wrap gap-4 text-left">{children}</div>
        <div className="flex flex-wrap gap-4">
          {status && <Chip variant={status.variant}>{status.label}</Chip>}
          <Icon
            className={cn(
              "transform-center justify-end group-state-open:rotate-180",
            )}
            name="chevron-down"
          />
        </div>
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
}

/* -------------------------------------------------------------------------------------------------
 * HeaderMeta
 * -----------------------------------------------------------------------------------------------*/
type HeaderMetaProps = React.PropsWithChildrenRequired<{
  label: string;
}>;

export function HeaderMeta({ label, children }: HeaderMetaProps) {
  return (
    <div
      className="flex gap-1 [&+&]:border-l [&+&]:border-solid [&+&]:border-grey-300 [&+&]:pl-4"
      onClick={event => event.stopPropagation()}
    >
      <span className="text-grey-700">{label}:</span>
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
type ContentProps = React.PropsWithChildrenRequired<{
  className?: string;
}>;

export function Content({ className, children }: ContentProps) {
  return (
    <AccordionPrimitive.Content
      className={cn("flex flex-col overflow-hidden", className)}
    >
      <div className="px-4 pb-4">{children}</div>
    </AccordionPrimitive.Content>
  );
}
